<template>
  <section>
    <div v-for="(group, i) in invoiceQueueList" :key="i">
      <aTable
        class="travel-table generate-invoices-table mb-0 relative"
        style="z-index: 2"
        :columns="columns"
        :data-source="group.data"
        :pagination="false"
        :footer="false"
        :loading="loadingInvoiceQueueList"
        :scroll="{ y: 800 }"
      >
        <div slot="sale_id" slot-scope="record">
          <aTooltip>
            <template #title> Contrato: {{ record.id }} </template>
            {{ record.sale_id }}
          </aTooltip>
        </div>

        <div slot="product" slot-scope="record">
          <div class="dotted-phrase upper">
            <a-tooltip :title="record.product">
              {{ record.product }}
            </a-tooltip>
          </div>
        </div>

        <div slot="traveller_name" slot-scope="record">
          <div class="dotted-phrase upper">
            <a-tooltip :title="record.traveller_name">
              {{ record.traveller_name }}
            </a-tooltip>
          </div>
        </div>

        <div slot="incomings" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="incomings"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="to_pay" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="to_pay"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="ticket_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="ticket_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="ticket_tax_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="ticket_tax_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="extra_fee_value" slot-scope="record">
          <GenerateInvoiceModalEditRowSection
            :contract="record"
            :editContractId="editContractId"
            column="extra_fee_value"
            @onClickChangeEditId="onClickChangeEditId"
            @updateContractsList="updateContractsList"
          />
        </div>

        <div slot="balance" slot-scope="record">
          {{ record.balance == 0 ? "---" : "---" }}
        </div>

        <div slot="status" slot-scope="record">
          <div
            class="status"
            :id="`contract-${record.id}-invoice-status-${record.product_id}-${record.traveller_id}`"
          >
            ---
          </div>
        </div>

        <div slot="action" slot-scope="record">
          <a
            v-if="!loadingRemoveItem"
            class="red f12"
            @click="onClickRemoveTravelerFromInvoice(record)"
          >
            Remover
          </a>
          <a-icon v-if="loadingRemoveItem" type="loading" />
        </div>
      </aTable>

      <aTable
        class="travel-table generate-invoices-table fw-600 cblack relative"
        style="margin-top: -25px; z-index: 1"
        :columns="[
          {
            title: false,
            dataIndex: 'fakeCol1',
            key: 'fakeCol1',
            width: 70,
          },
          {
            title: false,
            dataIndex: 'fakeCol2',
            key: 'fakeCol2',
            width: 90,
          },
          {
            title: false,
            dataIndex: 'fakeCol3',
            key: 'fakeCol3',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalTicket',
            key: 'totalTicket',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalTicketTax',
            key: 'totalTicketTax',
            width: 130,
          },
          {
            title: false,
            dataIndex: 'totalIncoming',
            key: 'totalIncoming',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalToPay',
            key: 'totalToPay',
            width: 120,
          },

          {
            title: false,
            dataIndex: 'totalExtraFee',
            key: 'totalExtraFee',
            width: 120,
          },
          {
            title: false,
            dataIndex: 'totalBalance',
            key: 'totalBalance',
            width: 100,
          },
          {
            title: false,
            dataIndex: 'Status',
            key: 'status',
            width: 100,
          },
          {
            title: false,
            dataIndex: 'Ação',
            key: 'action',
            width: 100,
          },
        ]"
        :data-source="totalsListBySale(group.data)"
        :header="false"
        :footer="false"
        :pagination="false"
        :scroll="{ y: 800 }"
      />
    </div>
  </section>
</template>

<script>
import { parse } from "vue-currency-input";
import _ from "lodash";
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";
import GenerateInvoiceModalEditRowSection from "@/components/finances/sections/GenerateInvoiceModalEditRowSection.vue";

export default {
  name: "InvoicesInQueueTab",
  components: {
    GenerateInvoiceModalEditRowSection,
  },
  mixins: [contractMixins, generateInvoicesCalcsAndRules, formatThings],
  data() {
    return {
      invoiceQueueList: [],
      editContractId: "",
      loadingRemoveItem: false,
      loadingInvoiceQueueList: false,
      columns: [
        {
          title: "Venda",
          scopedSlots: { customRender: "sale_id" },
          width: 70,
        },
        {
          title: "Loc.",
          dataIndex: "locator",
          key: "locator",
          width: 90,
        },
        {
          title: "Viajante",
          scopedSlots: { customRender: "traveller_name" },
          width: 120,
        },
        {
          title: "Bilhete",
          key: "ticket_value",
          width: 120,
          scopedSlots: { customRender: "ticket_value" },
        },
        {
          title: "Tx. Embarque",
          key: "ticket_tax_value",
          width: 130,
          scopedSlots: { customRender: "ticket_tax_value" },
        },
        {
          title: "A Receber",
          key: "incomings",
          width: 120,
          scopedSlots: { customRender: "incomings" },
        },
        {
          title: "A Pagar",
          key: "to_pay",
          width: 120,
          scopedSlots: { customRender: "to_pay" },
        },

        {
          title: "Taxa extra",
          width: 120,
          scopedSlots: { customRender: "extra_fee_value" },
        },
        {
          title: "Saldo",
          scopedSlots: { customRender: "balance" },
          width: 100,
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "Ação",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
    };
  },
  mounted() {
    this.contract.filters.invoiceStatus.selected = ["Na fila"];
    this.getInvoicesInQueue().then(({ data }) => {
      this.formatInvoiceQueueList(data.data);
    });
  },
  methods: {
    async getInvoicesInQueue() {
      this.loadingInvoiceQueueList = true;
      try {
        const data = await this.$http.get(
          `/contract-v2/list?page=1&per_page=999&invoice_status=Na fila&join-sales=yes&status=concluded&product-categories=flight&flight-type=Regular&order=desc&order-by=created`
        );
        return data;
      } catch (e) {
        console.log(e);
        this.invoiceQueueList = [];
      } finally {
        this.loadingInvoiceQueueList = false;
      }
    },
    onClickChangeEditId(editId) {
      this.editContractId = editId;
    },
    updateContractsList() {},
    formatInvoiceQueueList(arr) {
      let totalInvoices = 0;
      const groupInvoices = _.groupBy(
        this.newContractList(arr),
        "invoice_group"
      );

      const groups = Object.keys(groupInvoices);
      const invoices = Object.values(groupInvoices);

      groups.forEach((group, i) => {
        if (group) {
          this.invoiceQueueList.push({
            name: group,
            data: invoices[i],
          });

          totalInvoices += invoices[i].length;
        }
      });

      this.$emit(
        "updateGenerateInvoicesModal",
        this.invoiceQueueList,
        totalInvoices
      );
    },
    onClickRemoveTravelerFromInvoice(contract) {
      this.loadingRemoveItem = true;
      this.updateContractData({
        id: contract.id,
        [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_status`]:
          "Em aberto",
        [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_group`]:
          "",
      })
        .then(() => {
          this.getInvoicesInQueue()
            .then(({ data }) => {
              this.formatInvoiceQueueList(data.data);
            })
            .finally(() => (this.loadingRemoveItem = false));
        })
        .catch(() => (this.loadingRemoveItem = false));
    },
    async updateContractData(constractData) {
      try {
        const data = await this.$http.post(
          `/contract/update-multi-meta`,
          constractData
        );
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    totalsListBySale(arr) {
      let totalTicket = 0;
      let totalTicketTax = 0;
      let totalToPay = 0;
      let totalIncoming = 0;
      let totalIncomingBalance = 0;
      let totalExtraFee = 0;

      if (arr.length) {
        arr.forEach((passenger) => {
          if (typeof passenger.ticket_value === "number") {
            totalTicket += passenger.ticket_value;
          } else {
            totalTicket += parse(passenger.ticket_value);
          }

          if (typeof passenger.ticket_tax_value === "number") {
            totalTicketTax += passenger.ticket_tax_value;
          } else {
            totalTicketTax += parse(passenger.ticket_tax_value);
          }

          if (typeof passenger.to_pay === "number") {
            totalToPay += passenger.to_pay;
          } else {
            totalToPay += parse(passenger.to_pay);
          }

          if (typeof passenger.incomings === "number") {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += passenger.incomings;
            }
          } else {
            if (passenger.flightValueIsEqualPaymentValues) {
              totalIncomingBalance += 0;
            } else {
              totalIncomingBalance += parse(passenger.incomings);
            }
          }

          if (typeof passenger.incomings === "number") {
            totalIncoming += passenger.incomings;
          } else {
            totalIncoming += parse(passenger.incomings);
          }

          if (typeof passenger.extra_fee_value === "number") {
            totalExtraFee += passenger.extra_fee_value;
          } else {
            totalExtraFee += parse(passenger.extra_fee_value);
          }
        });
      }

      return [
        {
          fakeCol1: "TOTAL",
          fakeCol2: "",
          fakeCol3: "",
          totalIncoming: this.formatPricePtBr(totalIncoming),
          totalToPay: this.formatPricePtBr(totalToPay),
          totalTicket: this.formatPricePtBr(totalTicket),
          totalTicketTax: this.formatPricePtBr(totalTicketTax),
          totalExtraFee: this.formatPricePtBr(totalExtraFee),
          totalBalance: this.formatPricePtBr(
            totalToPay - totalExtraFee - totalIncomingBalance
          ),
        },
      ];
    },
  },
};
</script>
