<template>
  <span>
    <template v-if="contract.product_type === 'flight'">
      <div
        v-if="
          editContractId !==
          `contract-${contract.id}-product_id-${contract.product_id}-traveller_id-${contract.traveller_id}-${column}`
        "
      >
        {{ contract[column] | formatListPriceNumAndStr }}
        <aIcon
          class="c-pointer"
          type="edit"
          @click="
            onClickEditRow(
              `contract-${contract.id}-product_id-${contract.product_id}-traveller_id-${contract.traveller_id}-${column}`
            )
          "
        />
      </div>

      <div
        v-if="
          editContractId ===
          `contract-${contract.id}-product_id-${contract.product_id}-traveller_id-${contract.traveller_id}-${column}`
        "
      >
        <aInput
          class="value-input"
          v-model="theValue"
          v-currency="{
            currency: 'BRL',
            distractionFree: true,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: true,
            allowNegative: true,
          }"
          :disabled="updateValueLoading"
          @pressEnter="onClickUpdateValue"
        />

        <aIcon
          v-if="!updateValueLoading"
          class="c-pointer ml-5"
          type="close"
          @click="onClickEditRow('')"
        />
        <aIcon v-if="updateValueLoading" class="ml-5" type="loading" />
      </div>
    </template>

    <template v-if="['hotel', 'service'].includes(contract.product_type)">
      <template v-if="editContractId !== rowId">
        {{ contract[column] | formatListPriceNumAndStr }}

        <aIcon
          v-if="column != 'ticket_tax_value'"
          class="c-pointer"
          type="edit"
          @click="onClickEditRow(rowId)"
        />
      </template>

      <template v-if="editContractId === rowId">
        <aInput
          class="value-input"
          v-model="theValue"
          v-currency="{
            currency: 'BRL',
            distractionFree: true,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: true,
            allowNegative: true,
          }"
          :disabled="updateValueLoading"
          @pressEnter="onClickUpdateValue"
        />

        <aIcon
          v-if="!updateValueLoading"
          class="c-pointer ml-5"
          type="close"
          @click="onClickEditRow('')"
        />
        <aIcon v-if="updateValueLoading" class="ml-5" type="loading" />
      </template>
    </template>
  </span>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "GenerateInvoiceModalEditRowSection",
  props: {
    contract: Object,
    editContractId: String,
    column: String,
  },
  mixins: [formatThings],
  data() {
    return {
      theValue: 0,
      updateValueLoading: false,
      rowId: "",
    };
  },
  mounted() {
    if (["hotel", "service"].includes(this.contract.product_type))
      this.rowId = `contract-${this.contract.id}-product_id-${this.contract.product_id}-${this.column}`;
  },
  methods: {
    onClickEditRow(editId) {
      this.$emit("onClickChangeEditId", editId);

      if (typeof this.contract[this.column] === "string")
        this.theValue = this.contract[this.column];

      if (typeof this.contract[this.column] === "number")
        this.theValue = `${this.contract[this.column].toFixed(2)}`.replace(
          ".",
          ","
        );
    },
    onClickUpdateValue() {
      let updateValue = 0;

      if (this.theValue.includes("R$")) {
        updateValue = this.theValue.replace(".", "");
        updateValue = updateValue.replace("R$", "");
        updateValue = updateValue.trim();
      } else {
        updateValue = this.theValue;
      }

      let contractData = {
        id: this.contract.id,
      };

      updateValue = updateValue ? updateValue : 0;

      if (this.contract.product_type === "flight") {
        if (this.column === "incomings")
          contractData[
            `contract_finances_flight_${this.contract.product_id}_traveller_${this.contract.traveller_id}_incoming_value`
          ] = updateValue;

        if (this.column === "to_pay")
          contractData[
            `contract_finances_flight_${this.contract.product_id}_traveller_${this.contract.traveller_id}_to_pay_value`
          ] = updateValue;

        if (this.column === "ticket_value")
          contractData[
            `flight_${this.contract.product_id}_traveller_${this.contract.traveller_id}_ticket_value`
          ] = updateValue;

        if (this.column === "ticket_tax_value")
          contractData[
            `flight_${this.contract.product_id}_traveller_${this.contract.traveller_id}_ticket_tax_value`
          ] = updateValue;

        if (this.column === "extra_fee_value")
          contractData[
            `flight_${this.contract.product_id}_traveller_${this.contract.traveller_id}_extra_fee_value`
          ] = updateValue;
      }

      if (["hotel", "service"].includes(this.contract.product_type)) {
        if (this.column === "incomings")
          contractData[
            `contract_finances_${this.contract.product_type}_${this.contract.product_id}_incoming_value`
          ] = updateValue;

        if (this.column === "to_pay")
          contractData[
            `contract_finances_${this.contract.product_type}_${this.contract.product_id}_to_pay_value`
          ] = updateValue;

        if (this.column === "ticket_value")
          contractData[
            `contract_finances_${this.contract.product_type}_${this.contract.product_id}_ticket_value`
          ] = updateValue;

        if (this.column === "extra_fee_value")
          contractData[
            `contract_finances_${this.contract.product_type}_${this.contract.product_id}_extra_fee_value`
          ] = updateValue;
      }

      this.updateValueLoading = true;

      this.$http
        .post(`/contract/update-multi-meta`, contractData)
        .then(() => {
          this.$emit(
            "updateContractsList",
            updateValue,
            this.contract,
            this.column
          );
        })
        .finally(() => {
          this.updateValueLoading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.value-input
  width: 90px
  height: 20px
  border: 2px solid #ddd
  padding: 3px
  font-size: 12px
  border-radius: 3px
</style>
