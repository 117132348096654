var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.contract.product_type === 'flight')?[(
        _vm.editContractId !==
        `contract-${_vm.contract.id}-product_id-${_vm.contract.product_id}-traveller_id-${_vm.contract.traveller_id}-${_vm.column}`
      )?_c('div',[_vm._v("\n      "+_vm._s(_vm._f("formatListPriceNumAndStr")(_vm.contract[_vm.column]))+"\n      "),_c('aIcon',{staticClass:"c-pointer",attrs:{"type":"edit"},on:{"click":function($event){return _vm.onClickEditRow(
            `contract-${_vm.contract.id}-product_id-${_vm.contract.product_id}-traveller_id-${_vm.contract.traveller_id}-${_vm.column}`
          )}}})],1):_vm._e(),_vm._v(" "),(
        _vm.editContractId ===
        `contract-${_vm.contract.id}-product_id-${_vm.contract.product_id}-traveller_id-${_vm.contract.traveller_id}-${_vm.column}`
      )?_c('div',[_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
          currency: 'BRL',
          distractionFree: true,
          valueAsInteger: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: true,
        }),expression:"{\n          currency: 'BRL',\n          distractionFree: true,\n          valueAsInteger: false,\n          precision: 2,\n          autoDecimalMode: true,\n          allowNegative: true,\n        }"}],staticClass:"value-input",attrs:{"disabled":_vm.updateValueLoading},on:{"pressEnter":_vm.onClickUpdateValue},model:{value:(_vm.theValue),callback:function ($$v) {_vm.theValue=$$v},expression:"theValue"}}),_vm._v(" "),(!_vm.updateValueLoading)?_c('aIcon',{staticClass:"c-pointer ml-5",attrs:{"type":"close"},on:{"click":function($event){return _vm.onClickEditRow('')}}}):_vm._e(),_vm._v(" "),(_vm.updateValueLoading)?_c('aIcon',{staticClass:"ml-5",attrs:{"type":"loading"}}):_vm._e()],1):_vm._e()]:_vm._e(),_vm._v(" "),(['hotel', 'service'].includes(_vm.contract.product_type))?[(_vm.editContractId !== _vm.rowId)?[_vm._v("\n      "+_vm._s(_vm._f("formatListPriceNumAndStr")(_vm.contract[_vm.column]))+"\n\n      "),(_vm.column != 'ticket_tax_value')?_c('aIcon',{staticClass:"c-pointer",attrs:{"type":"edit"},on:{"click":function($event){return _vm.onClickEditRow(_vm.rowId)}}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.editContractId === _vm.rowId)?[_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
          currency: 'BRL',
          distractionFree: true,
          valueAsInteger: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: true,
        }),expression:"{\n          currency: 'BRL',\n          distractionFree: true,\n          valueAsInteger: false,\n          precision: 2,\n          autoDecimalMode: true,\n          allowNegative: true,\n        }"}],staticClass:"value-input",attrs:{"disabled":_vm.updateValueLoading},on:{"pressEnter":_vm.onClickUpdateValue},model:{value:(_vm.theValue),callback:function ($$v) {_vm.theValue=$$v},expression:"theValue"}}),_vm._v(" "),(!_vm.updateValueLoading)?_c('aIcon',{staticClass:"c-pointer ml-5",attrs:{"type":"close"},on:{"click":function($event){return _vm.onClickEditRow('')}}}):_vm._e(),_vm._v(" "),(_vm.updateValueLoading)?_c('aIcon',{staticClass:"ml-5",attrs:{"type":"loading"}}):_vm._e()]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }